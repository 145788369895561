.uploadpart{
    border: none;
    font-size: 1rem; 
   padding: 0.5rem;
    /* padding: .7rem 1rem; */
     width: 13rem; 
    /* display: inline-flex; */
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
    border-color: none;
    background: linear-gradient(180deg,#7182af,#8696c2 50%,#7187bd);
  }
  .upload-file-job{
    width:60%;
    color: black;
    text-align: left; 
    margin:0 0 0 10px;
}
.uploadpart:hover{
  background: linear-gradient(180deg,#6d80b3,#7a8cbd 100%,#6d86c5);
}


@media only screen and (max-width: 991px){
  .uploadpart{
    font-size: 0.8rem;
    
   
  } 
  .upload-file-job{
    font-size: 15px;
   
  } 
}
@media only screen and (max-width: 530px){
  
  .upload-file-job{
    font-size: 11px;
  }
}
/* @media only screen and (max-width: 377px){
  .uploadpart{
    font-size: 0.7rem;
    width: 10rem;
    padding: 0.7rem;
  }
  .upload-file-job{
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 313px){
  .uploadpart{
    font-size: 0.6rem;
    width: 8rem;
    padding: 0.5rem;
  }
  
}
@media only screen and (max-width: 285px){
  .uploadpart{
    font-size: 0.5rem;
    width: 7rem;
    padding: 0.5rem;
  }
  
}
@media only screen and (max-width: 261px){
  .upload-file-job{
    font-size: 0.5rem;
  }
  
} */