.header-image {
  height: 20rem;
  width: 100%;
}
.header-image img {
  height: 20rem;
  width: 100%;
  object-fit: cover;
}
.header-image::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  /* top: 0; */
  width: 100%;
  height: 20rem;
  /* 350px */
  background-color: rgba(49, 158, 221, 0.4);
  z-index: 2;
}
.text {
  z-index: 3;
  color: white;
  position: relative;
  top: -14rem;
  left: 3rem;
  text-align: left;
  text-align: justify;
  padding: 0;
  width:60%;
}
.heading-career {
  margin: 0;
  font-size: 30px;
}
.text p {
  margin-top: 5px;
  width:clamp(200px, 70%,400px )
 
}


