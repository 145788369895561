.ctext{
  text-align: center;
  margin:1rem 0;
  font-family: "Rubik", sans-serif;
  font-size: 30px;
}
.coremain{
  background-color: aqua;
}

.coregrid {
    display: grid;
    grid-template-columns: 30% 2% 30% 2%30%;
    gap: 1.5rem;   
    text-align: center;
    padding-bottom :1rem;
    padding-top: 0.2rem;
    width: 82.5%;
    margin:auto;
    justify-content: space-between;
    
  }
  .core-icon1{
    height: 65px;
    width: 65px;
    margin-left: -10px;
  }
  .core-icon2{
    height: 35px;
    width: 40px;
    margin-bottom: 10px

  }
  .core-icon3{
    height: 35px;
    width: auto;
    margin-bottom: 10px;
  }
  .line hr{
    transform: rotate(180deg);
    /* border:1px solid black; */
    /* padding:.1rem; */
    background-color:black;
    width:.1rem;
    height:90%;
    margin-left: 0.5rem;
    text-align: center;
  }
  .cvp{
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 5px;
    color: #005792;
  }
  .corep{
    text-align: justify;
    hyphens: auto;
    font-size: 15px;
  }
  .valuetext1{
    margin-top: 5rem;
    text-align: justify;
    width: 90%;
    
    /* margin: auto; */

  }
  .valuetext2{
    margin-top: 3rem;
    text-align: justify;
    width: 90%;
    /* margin: auto; */
    
  }
  .valuetext3{
    margin-top: 1rem;
    text-align: justify;
    width: 90%;
    /* margin: auto; */
  }

  @media only screen and (max-width: 1150px) {
    .valuetext1{
      width: 100%;
  
    }
    .valuetext2{
      width: 100%;
      
    }
    .valuetext3{
      width: 100%;
    }

 }
 @media only screen and (max-width: 950px){
  p.cvp{
    font-size: 1.2rem;

  }
  .coregrid{
    gap: 1rem;
  }
  p.corep {
    font-size: 12px;
  }


 }
 





  @media only screen and (max-width: 650px) {
    .coregrid {
    
      grid-template-columns: 85% ;
      /* text-align: center; */
      /* margin:auto; */
    } 

    .line {
      content-visibility: hidden;
  }
    .valuetext1{
     margin-top: 0px;
    text-align: justify;
    width: 100%;
    margin-left: 20px;
  
    }
    .valuetext2{
      margin-top: 0px;
      text-align: justify;
      width: 100%;
      margin-left: 20px;
      
    }
    .valuetext3{
      text-align: justify;
      width: 100%;
      margin-left: 20px;
    }
  }