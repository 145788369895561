
.frompara{
  font-size: 1.6rem !important;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  gap: 1rem;
  padding: 10px 30px;
  width: 100%;
}
.form-container .form_container {
  background-color: transparent;
  width: 100%;
  padding: 0;
}

.form-left {
  /* width: 650px; */
  width: 60%;
  background-color: #487eb0;
  /* background-color: #3498db; */
  color: #ffffff;
  height: auto;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}
.apple {
  display: flex;
  gap: 1rem;
}

h2 {
  margin: 0.6rem 0;
  /* margin-top: 0.3rem; */
  font-size: 1.6rem;
}

.spanclass {
  /* text-align: left; */
  margin: 0.2rem 0;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}
.left h2 {
  font-weight: 500;
  font-size: 18px;
}
.form-left p{
  /* color: white; */
  font-size: 0.8rem;
  padding: 0 0 0 5px;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
.left span {
  font-size: 15px;
}

input {
  width: 600px;
  padding: 14px 10px;
  outline: none;
  border: none;
  border-radius: 4px;
  margin: 10px 0;
}

textarea {
  outline: none;
  border: none;
  resize: none;
  width: 600px;
  height: 70px;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
}

.form_container .submit-button {
  /* margin-top: 1rem; */
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrap .second {
  display: block;
  width: 100%;
  padding: 0;
}
.btn:hover {
  /* background-color: #0D4A93; */
}
div .css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root:hover,
div .css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root:hover {
  /* background-color: rgba(0, 0, 0, 0.09); */
  background-color: white;
}
div .css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root:focus-visible {
  /* background-color: rgba(0, 0, 0, 0.09); */
  background-color: white;
}
.css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root.Mui-focused {
  background-color: #ffffff;
}
.form-file {
  width: 10rem;
}
label .file_button {
  margin-top: 1rem;
  width: 100%;
}

.submit {
  align-self: center;
  width: 180px;
  background-color: #0d4a93;
  color: #ffffff;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
}

.submit:hover {
  /* color: #0D4A93; */
  background-color: #fff;
  font-weight: bold;
}

.country {
  display: flex;
  gap: 15px;
}

.country input,
.first input {
  width: 285px;
  /* margin-right: 25px; */
  /* color: #fff; */
}

/* .country label{
    color: #fff;
} */

#textarea {
  width: 610px;
  background-color: #fff;
}

.form-right {
  /* width:28%; */
  /* margin-top: 90px; */
  margin: 5rem 1rem 1rem 1rem;
}

.css-191j2fa .connected {
  /* font-size: 25px; */
  font-weight: bold;
  margin: 10px 0;
  /* color: #3498db; */
  color: #487eb0;
}

.info {
  font-size: 15px;
  margin-bottom: 60px;
}

.icon-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0.5rem;
  /* background-color: #3498db; */
  background-color: #487eb0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 10px;
}
div .file_button {
  background-color: white;
  color: black;
}
div .file_button:hover {
  background-color: white;
}
.contact {
  display: flex;
  /* justify-content: flex-start; */
  /* justify-content: center; */
  align-items: center;
  margin: 20px 10px;
}

.icon-text {
  font-weight: 500;
}

.contact-details {
  display: block;
  font-weight: bold;
  font-size: 18px;
  /* float: left; */
  /* text-align: center; */
}

.textfield {
  background-color: #fff;
  border-radius: 10px;
}

.textfield:hover {
  outline: none;
}
.contact-container {
  /* width: 200px;
  margin: auto; */
  /* display: flex;
  justify-content: center; */
}
div .css-gjbq6i-MuiInputBase-root-MuiFilledInput-root {
  background-color: #fff;
  padding: 0px 0px;
}

div .css-c5v1qu-MuiInputBase-input-MuiFilledInput-input {
  background-color: #fff;
}
div .css-c5v1qu-MuiInputBase-input-MuiFilledInput-input {
  background-color: #fff;
  border-radius: 10px;
}

div .css-1p6y6ap-MuiInputBase-root-MuiFilledInput-root,
div .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root,
div .css-gjbq6i-MuiInputBase-root-MuiFilledInput-root,
div .css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root {
  background-color: white;
  border-radius: 10px;
}
.file_button {
  margin-left: 2rem;
}
.css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root {
  background-color: #fff;
}
div .css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root.Mui-focused,
div .css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root:hover {
  background-color: white;
}
.MuiFormControl-root.MuiFormControl-marginDense.MuiFormControl-fullWidth.MuiTextField-root.txtfield_country_code.css-191j2fa {
  background-color: white;
}
div .txtfield_country_code {
  border-radius: 10px;
}
@media (hover: none) {
  div .css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root:hover {
    background-color: white;
  }
}

@media screen and (max-width: 500px) {
  div .css-1mog9k0-MuiFormLabel-root-MuiInputLabel-root{
    padding-top: 3px!important;
  }
}
@media screen and (max-width: 700px) {
  .form-container {
    padding: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .form-container {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }
  .form-left {
    width: 90%;
    margin: 1rem auto;
  }
  .form-right {
    width: 90%;
    margin: auto;
  }
  .info {
    margin-bottom: 1rem;
  }
}