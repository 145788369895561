.offer {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    background-color: #dddee65e; 
    padding-bottom: 1rem;
  }
  
  .left {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 1rem;
    margin-top: 40px;
  }
  .right {
    width: 55%;
  }
  
  .title {
    font-size: 25px;
    font-family: "Rubik", sans-serif;
    text-transform: uppercase;
    /* font-weight: bold; */
    color: #130f40;
    margin: 10px 0;
  }
  
  .title-info {
    font-size: 18px;
    color: #3498db;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .offer-content {
    font-size: 15px;
    text-align: justify;
  }
  
  .boxes {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  
  
  .right {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* padding-top: 20px; */
    /* padding: 20px 40px; */
    margin-top: 50px;
    width: 45%;
  }
  
  .right-title {
    color: #3498db;
    font-size: 18px;
  }
  
  .subtitle {
    color: #130f40;
    font-size: 26px;
    font-weight: 500;
  }
  .subtitle-content{
    text-align: justify;
  }
  /* .processes {
    margin-top: 10px;
  } */
  
  .process {
    margin: 5px 0px;
    padding: 8px;
    border-radius: 10px;
    /* cursor: pointer; */
  }
  
  .process-content {
    margin-left: 43px;
    text-align: left;
    text-align: justify;
    font-size: 15px;
  }
  
  .process:hover 
  {
    background-color: #e2e8f0; 
  }
  
  .process:hover .circle {
    background-color: #fff;
  }
  .process-title {
    display: flex;
    color: #130f40;
  }
  
  .circle {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    color: #130f40;
    background-color: #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-right: 15px;
    font-weight: 500;
  }
  
  .process-text {
    color: #130f40;
    font-weight: bold;
    font-size: 15.3px;
  }
  
  .parent {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    /* background-color: red ; */
  }
  .accordion-button {
    font-size: 1.2rem;
  }
  .parent.accordion-item {
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .accordion-item:first-of-type .accordion-button,
  .bg-color-contract {
    background-color: #3498db;
  }
  .accordion-header{
    margin: 0;
  }
  .details {
    background-color: white;
    text-align: justify;
    font-size: 15px;
  }
  div .accordion-button:focus {
    border: none;
    box-shadow: none;
    /* color: black; */
  }
  div .accordion-button{
    /* color:white; */
    /* font-size: 1.1rem; */
    font-weight: 500;
  }
  .accordion-item:nth-child(2) .accordion-button,
  .bg-color-hire {
    background-color:#5daee4;
  }
  .accordion-item:nth-child(3) .accordion-button,
  .bg-color-cont2hire {
    background-color: #7dbee9;
  }
  /* div .accordion-button{
      background-color: #22c55e;
  } */
  .accordion-button{
    border-radius:8px !important;
  }
  .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #29008a;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }
  .accordion-button:not(.collapsed)::after{
    background-image:var(--bs-accordion-btn-icon);
  }
  @media screen and (max-width: 770px) {
    
  
    .left {
      width: 95%;
      margin: auto;
      /* padding: 5px; */
    }
  
    .title-info {
      font-size: 14px;
    }
  
    .offer-content {
      font-size: small;
      /* width: 310px; */
    }
  
    /* .box {
      padding: 10px 20px;
    } */
  
    .right {
      width: 60%;
      /* padding: 5px; */
      margin: 1rem;
    }
  
    .subtitle {
      font-size: 24px;
    }
  
    .process-text {
      font-size: 15.5px;
    }
    .process {
      padding: .5rem;
    }
    .process-content {
      font-size: 14px;
      width: 260px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .offer {
      display: flex;
      flex-direction: column;
    }
    .right {
      padding: 0;
      margin:auto;
    }
    .process{
      margin:1rem 0 ;
      padding:.5rem;
    }
    .process:nth-child(1){
      margin-top:0;
    }
  }
  
  @media screen and (max-width: 425px) {
    .offer {
      flex-direction: column;
      width: 100%;
    }
  
    .left {
      width: 81%;
      margin: auto;
      padding: 7px;
    }
  
    .title-info {
      font-size: 14px;
    }
  
    .offer-content {
      font-size: small;
      /* width: 310px; */
    }
/*   
    .box {
      width: 75%;
      padding: 10px 20px;
    } */
  
    .right {
      width: 80%;
      margin: auto;
      padding: 5px;
    }
  
    
    
  
    .process-content {
      font-size: 12px;
      width: 75%;
    }
  }
  
  @media screen and (max-width: 320px) {
    .offer {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }
  
    .title-info {
      font-size: 15px;
    }
  
    .left {
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 0;
      /* padding: 10px; */
      padding: 6px;
    }
  
    .offer-content {
      text-align: left;
    }
/*   
    .box {
      width: 75%;
    } */
  
    .offer-content {
      font-size: 14px;
    }
  
    .process-content {
      margin-left: 43px;
      text-align: left;
      width: 90%;
      font-size: 14px;
    }
  
    .right {
      width: 100%;
      margin: 0;
      padding: 10px;
    }
  }
  