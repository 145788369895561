.text1 {
  text-align: center;
  margin: 1.5rem 0 1rem 0;
  font-family: "Rubik", sans-serif;
  font-size: 30px;
}
.second {
  display: grid;
  grid-template-columns: 45% 55%;
  padding: 0rem 0 1rem 0;
  width: 80%;
  margin: auto;
}
.second img {
  width: 100%;
}

.image {
  width: 100%;
  height: 80%;
  margin: auto;
  z-index: 1;
}
.image img {
  height: 100%;
  object-fit: cover;
}
.textBox {
  position: relative;
  padding: 1rem 0 0 0;
  background-color: rgb(205, 231, 239);
  margin: .5rem 0rem 0rem -4rem;
  box-shadow: 6px 5px 5px rgba(0, 0, 0, 0.25);
}
.textBox p {
  width: 100%;
  
}
/* 
.textBox:hover {
  transition: 0.6s ease-in-out;
  margin-left: -100px;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
} */

.textBox h5 {
    display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-weight: lighter;
}
/* .textBox:hover h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */
.textBox p {
  /* display: flex; */
  padding: 1rem 2.5rem 2rem  7rem;
  font-style: normal;
  /* font-family: "Calibri", "sanserif"; */
  text-align: justify;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* letter-spacing: 0.03em; */
  font-size: 15px;
}

@media only screen and (max-width: 820px) {
  .textBox p {
    
    padding: .5rem 2.5rem 2rem  6rem;
     letter-spacing: 0em;
  }

}
@media only screen and (max-width: 946px){
  .second{
    width: 95%;
  }
}
@media only screen and (max-width: 760px) {
  .textBox p {
    font-size: 0.75rem;
    padding: 0.5rem 2.5rem 2rem 5rem ;
  }
  .second {
    width: 95%;
  }
  /* .textBox{
    padding: 1rem 0;
  } */
}
@media only screen and (max-width: 650px){
  .second{
    grid-template-columns: 90%;
    justify-content: center;
    text-align: center;
    grid-row-gap: 0;
  }
  .textBox{
    margin: 0;
    padding: 0
  }
  .textBox p{
    padding: 1.5rem;
    font-size: 0.8rem;
  }
  .image{
    height: 100%;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1920px){
  .know-more-btn-div{
    padding: 0 2.5rem;
  }
}
@media screen and (min-width: 1881px) and (max-width: 1920px){
  .know-more-btn-div{
    padding: 0 2.9rem;
  }
}
@media screen and (min-width: 1920px) and (max-width: 2024px){
  .know-more-btn-div{
    padding: 0 3rem;
  }
}
@media screen and (min-width: 1650px) and (max-width: 1832px){
  .textBox p{
    padding-top: 2.5rem;
    font-size: 16px;
  }
}
@media screen and (min-width: 1833px) and (max-width: 2024px){
  .textBox p{
    padding-top: 2rem;
    font-size: 17px;
  }
}