.work-with-us-container {
  margin: auto;
}
.workwithus-maindiv {
  /* border: 2px solid;   */
  width: 100%;
  margin: auto;
  
  padding-bottom: 4.5rem;
  background-color: rgba(202, 199, 199, 0.171);
}
.workwithus-maindiv h2 {
  text-align: center;
  padding-bottom: 0.5rem;
   padding-top: 1.5rem; 
   font-family: "Rubik", sans-serif;
   font-size: 30px;
}
.box-row {
  border: 1px blue;
  column-gap: 7rem;
  justify-content: center;
  /* justify-content: space-around; */
}
.box-col {
  /* border: 1px solid red; */
  background: linear-gradient(
    180deg,
    rgba(7, 16, 229, 0.1) 0%,
    rgba(35, 171, 230, 0.3) 100%
  );
  height: 230px;
  width: 230px;
  transform: rotate(45deg);
  margin-top: 3rem;
  border-radius: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  position: relative;
}
.box-col:hover,
.box-col2:hover,
.box-col3:hover {
  background: linear-gradient(
    180deg,
    rgba(7, 14, 229, 0.267) 0%,
    rgba(35, 171, 230, 0.377) 100%
  );
  box-shadow: 0 4px 8px 0 rgba(133, 200, 245, 0.3),
    0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.box-row2 {
  /* border: 1px solid blue; */
  gap: 7rem;
  justify-content: center;
}
.box-col2 {
  /* border: 1px solid red; */
  background: linear-gradient(
    180deg,
    rgba(7, 16, 229, 0.1) 0%,
    rgba(35, 171, 230, 0.3) 100%
  );
  height: 230px;
  width: 230px;
  transform: rotate(45deg);
  margin-left: 7rem;
  border-radius: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  /* margin-top: 2.5rem; */
}

.box-col3 {
  /* border: 1px solid red; */
  background: linear-gradient(
    180deg,
    rgba(7, 16, 229, 0.1) 0%,
    rgba(35, 171, 230, 0.3) 100%
  );
  height: 230px;
  width: 230px;
  transform: rotate(45deg);
  margin-right: 7rem;
  border-radius: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  /* margin-top: 2.5rem; */
}
.parabox-text {
  /* border: 1px solid;  */
  position: absolute;
  right: 0.5rem;
  /* bottom: 3rem;  */
  transform: rotate(-45deg);
  text-align: center;
  margin: 0.5rem 1rem 0 0.5rem;
  width: 90%;
}
.icon1 {
  width: 100%;
  height: 100%;
  font-size: 25px;
  color: #00635a;
  margin-left: -5px; 
  /* margin-top: 0px; */
  /* margin-bottom: 0px; */
}
.icon2 {
  width: 100%;
  height: 100%;
  font-size: 25px;
  color: #f2668b;
  margin-left: -3px;
  margin-right: 30px;
  margin-bottom: 2px;
  /* margin-top: 0px; */
  /* margin-bottom: 0px; */
}
.icon3 {
  width: 100%;
  height: 100%;
  font-size: 25px;
  margin-left: -10px;
  color: #7a577a;
  margin-right: 30px;

  /* margin-top: 0px; */
  /* margin-bottom: 0px; */
}
.icon4 {
  font-size: 25px;
  color: #d96941;
  padding-top: 5px;
   margin-right: -8px; 
  margin-bottom: 2px;
  /* margin-top: 0px; */
  /* margin-bottom: 0px; */
}
.icon5 {
  font-size: 21px;
  color: rgba(54, 54, 231, 0.692);
  /* margin-right: 15px; */
  padding-top: 5px;
  /* margin-top: 0px; */
  /* margin-bottom: 0px; */
}
.icon1-h5 {
  margin-bottom: 7px;
  margin-top: 3px;
   margin-right: 10px; 
  font-size: 18px;
}
.icon2-h5 {
  margin-bottom: 7px;
  margin-top: 3px;
   margin-right: 10px; 
  font-size: 18px;
}
.icon3-h5 {
  margin-bottom: 7px;
  margin-top: 3px;
   margin-right: 23px; 
  font-size: 18px;
}
.icon4-h5 {
  margin-bottom: 7px;
  margin-right: -5px;
  font-size: 18px;
 
}
.icon5-h5 {
  margin-bottom: 7px;
  margin-right: -7px;
  font-size: 18px;
}
.box1-text {
  font-size: 12.6px;
  padding-left: 0;
  text-align: center;
  margin-right: 10px; 
}
.box2-text {
  font-size: 12.6px;
  padding-left: 0;
  text-align: center;
  margin-right: 10px; 
}
.box3-text {
  font-size: 12.6px;
  padding-left: 0;
  text-align: center;
  margin-right: 20px; 
}
.box4-text {
  font-size: 12.6px;
  padding-left: 0;
  text-align: center;
  margin-right: -3px; 
}
.box5-text {
  font-size: 12.6px;
  padding-left: 0;
  text-align: center;
  margin-right: -3px; 
 
}


@media (min-width: 993px) and (max-width: 1070px) {
  div .workwithus-maindiv {
    width: 100%;
    margin: 0;
  }
  .box-col,
  .box-col2,
  .box-col3 {
    height: 215px;
    width: 215px;
  }
  .box-row{
    column-gap: 6rem;
  }
  .icon1{
    margin-left: 7px;
  }
  .icon1-h5{
    margin-right: -10px;
  }
  .box1-text{
    margin-left: 8px;
    margin-right: 0;
    font-size: 12.1px;
  }
  .icon2{
    margin-left: 5px;
  }
  .icon2-h5{
    margin-right: 0;
  }
  .box2-text{
    margin-right: 0;
    font-size: 12.1px;
  }
  .icon3{
    margin-left: -5px;
  }
  .icon3-h5{
    margin-right: 10px;
  }
  .box3-text{
    margin-right: 10px;
    font-size: 12.1px;
  }
  .icon4{
    margin-right: -19px;
  }
  .icon4-h5{
    margin-right: -20px;
  }
  .box4-text{
    margin-right: -12px;
    font-size: 12.1px;
  }
  .icon5{
    margin-right: -13px;
  }
  .icon5-h5{
    margin-right: -12px;
  }
  .box5-text{
    margin-right: -10px;
    font-size: 12.1px;
  }
}
@media only screen and (max-width: 991px){
  .box-col2{
    margin-top: 3rem;
  }
  .box-col3{
    margin-right: 0;
  }
}
@media only screen and (max-width: 870px){
  .box-row{
    column-gap: 5.5rem;
  }
}
/* @media only screen and (max-width: 995px) {
  .box-col,
  .box-col2,
  .box-col3 {
    height: 180px;
    width: 180px;
  }
  .parabox-text {
    position: absolute;
    right: 1.1rem;
    bottom: 2rem;
    margin-right: 2rem;
    margin: 0;
    font-size: 0.9rem;
    width: 93.2%;
  }
  .box1-text {
    margin: 0;
    font-size: 11px;
  }
  .box-row {
    column-gap: 5.5rem;
  }
  .icon1-h5,
  .icon1,
  .icon2,
  .icon3,
  .icon4 {
    margin-right: 0;
    margin-left: 0;
  }
} */

@media only screen and (max-width: 767px) {
  .box-row {
    column-gap: 3.5rem;
  }
  .box-col {
    margin: 5.5rem 0 0 2rem;
  }
  .box-col2 {
    margin: 5.5rem 0 0 2rem;
  }
  .box-col3 {
    margin: 0;
    margin-left: 2rem;
    margin-top: 5.5rem;
  }
}
/* @media (min-width: 768px) and (max-width: 830px) {
  .workwithus-maindiv {
    margin: 0 0 5rem 0;
  }
} */
@media only screen and (max-width: 600px) {
  .box-row {
    justify-content: left;
    row-gap: 5.3rem;
    width: 90%;
    margin: 0 auto;
  }

  .box-col,
  .box-col2,
  .box-col3 {
    margin: auto;
  }
  .box-col3 {
    /* margin-left:9rem; */
    margin: 0rem 0 0 7rem;
  }
  .work-with-us-container {
    margin-top: 3rem;
    width: 90%;
  }
  .box-row2 {
    margin-top: 6rem;
    gap: 6rem;
  }
}
@media only screen and (max-width: 540px) {
  .box-col3 {
    margin: 0 0 0 5rem;
  }
}
@media only screen and (max-width: 486px) {
  .box-col3 {
    /* margin:0 0 0 7rem; */
    margin: auto;
  }
}
