.ourClients_container{
    width:86%;
    margin:auto;
    margin-top: 1.3rem;
    margin-bottom: 1rem;
}
.ourClients_container h2{
    font-family: "Rubik", sans-serif;
    font-size: 30px;
}
.clients_logo{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 200px; */
    width:100%;
    height: 9.5rem;
    /* padding:2rem 0; */
    margin:auto;
    /* border: 1px solid red; */
}
.company-logo{
    width:100% !important;
    margin:auto !important;
    /* background-color: red; */
    /* border: 1px solid; */
}
.company-logo img{
    margin: auto;
    width:60%;
   
    /* background-color: red; */
    /* border: 1px solid green; */
}

.company-logo h3{
    text-align: center;
}
.logo-name{
    text-align: center;
    font-size: 1rem;
}
.slick-dots{
    /* padding:1rem; */
    margin:1rem;
}
/* .slick-arrow.slick-next,.slick-arrow.slick-prev{
    display:none !important;
} */
.slick-next,.slick-prev{
    padding: 10px;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    border: none;
    border-radius: 5vw;
    background-color: #fff;
    z-index: 10!important;
    box-shadow: 0 0 20px 5px rgb(35 35 35 / 15%);
    /* change 50% to 15% for lower dark box shadow */
    top: 40%;
}
.slick-prev{
    left:-46px;
}
.slick-next{
    right:-45px;
}
.slick-disabled{
    opacity: 0;
    display: none;
    pointer-events: none;
}
@media only screen and (max-width: 750px){
    .clients_logo{
        height: 5rem;
    }
    .company-logo img{
        width:8rem;
    }
    .slick-prev,.slick-next{
        padding:.1rem;
        width:1.5rem;
        height: 1.5rem;
    }
    .slick-next{
        right:-10px;
    }
    .slick-prev{
        left:-20px;
    }
}
@media only screen and (max-width: 450px){
    .slick-next{
        right:-10px;
    }
    .slick-prev{
        left:-10px;
    }
}