.gotop{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
 }
   .top-btn {
     font-size: 1.5rem;
      width: 2.3rem;
      height: 2.5rem;
      color: #fff;
      background-color:#1C67F6;
      border-radius: 10%;
      position: fixed;
      bottom: 2rem;
      right: 1.2rem;
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      
   }
   .top-btn:hover
   {
     background: #1453c9;
   }
   .top-btn .tooltiptext{
    visibility: hidden;
    width: 70px;
    height: 22px;
    /* background-color: rgba(128, 128, 128, 0.664); */
    background-color: grey;
    color: white;
    text-align: center;
    border-radius: 6px;
    font-size: 13px;
    padding-left: 2px;
    padding-right: 2px;
    position: absolute;
    z-index: 1;
    bottom: -70%;
    left: 50%;
    margin-left: -35px;
  }
  .top-btn:hover .tooltiptext{
    visibility: visible;
  }
  /* .top-btn--icon {
    animation: 1.2s linear infinite alternate-reverse;
  } */
