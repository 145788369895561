*{
    box-sizing:border-box;
}
body{
    margin:0;
    padding:0;
}
h1{
    /* font-size:1.6rem; */
}
h2{
    /* font-size:1.2rem; */
}
.flex_direction_row{
    display: flex;
}
