.Servicemainbody {
  /* height:400px; */
  /* background-color: aquamarine; */
  /* padding: 2rem 3rem 5rem 3rem; */
  padding: 0.8rem;
  margin: 0.2rem 0 1rem 0;
}
.Servicemainbody h2 {
  padding-bottom: 0.5rem;
  font-family: "Rubik", sans-serif;
  font-size: 30px;
}
.Servicemainbody h2,
.service-paragraph {
  display: flex;
  justify-content: center;
  text-align: center;

  /* align-items: center; */
  /* color: #05A5FF; */
}
.Servicemainbody .service-paragraph {
  font-size: 1rem;
  margin-bottom: 1.7rem;
}

.mainbox {
  display: grid;
  justify-content: center;
  align-items: center;
  /* grid-template-rows: 300px 300px; */
  grid-template-columns: 23% 23% 23% 23%;
  grid-column-gap: 1%;
  /* grid-row-gap: 4%; */
  width: 90%;
  margin: auto auto;
  /* padding: 2rem; */
  /* background-color: aqua; */

}
.mainData {
  padding: 1rem 0.5rem;
  height: 17.5rem; 
  border: 1px solid rgba(156, 154, 154, 0.253); 
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 0.8rem;
  
}
.serviceimage {
  height: 5rem;
  
}

.mainData img {
  /* height:30%; */
  width: 40%;
  border-radius: 1rem;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
}
.mainData h4 {
  /* margin-top: 0.8rem; */
  font-size: 1rem;
  /* width: 80%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainData p {
  padding: 0 12px; 
  font-size: 13.3px;
  width: 100%;
  height: 75px;
 letter-spacing: -0.1px;
  /* margin: 0 auto; */
  /* text-align: center;    */
  line-height: 18px; 
  text-align: justify; 
  hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
}
.services-readmore{
  color: blue;
  text-align: center;
  padding-top: 10px;
  font-size: 14px;
  padding-bottom: 1rem;
  margin: 0.3rem auto;
}
.mainData:hover {
  /* height: 18rem; */
  /* border: 1px solid #F5E9E9; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.8rem;
  /* padding: 1rem; */
}

div .modalmain {
  /* margin-top: 0.5rem; */
  max-width: 1100px;
  /* height: 800px !important; */
}

.modal-header {
  padding-top: 15px;
  padding-right: 20px;
  border-bottom: none;
}
.modal-body {
  padding: 0;
}
div .modalmain .modalheader {
  /* padding: .8rem; */
  position: relative;
  cursor: pointer;
}
/* div .modal-header{
  padding:1rem;
} */
div .modalmain .closeicon {
  /* color: blue; */
  /* margin-top: -8px; */
  font-size: 1.9rem;
  cursor: pointer;
}

div .modalmain .closeicon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
}
div .modalmain .closeicon :hover {
  color: blue;
}
@media only screen and (min-width: 1025px) {
  .serviceimage {
    /* height: 4.5rem; */
    /* margin-bottom: 1rem; */
  }
}
@media only screen and (min-width: 1700px) {
  .serviceimage {
    height: 6rem;
    /* margin-bottom: 2.5rem; */
  }
}
@media only screen and (min-width: 2100px) {
  .serviceimage {
    height: 6rem;
    /* margin-bottom: 4.5rem; */
  }
}

@media only screen and (max-width: 1250px) {
  .serviceimage {
    height: 4rem;
  }
  .mainData img {
    width: 50%;
    height: 100%;
  }
}
@media only screen and (max-width: 930px) {
  .mainbox {
    grid-template-columns: 50% 50%;
    padding: 0.1rem;
    /* background-color: aqua; */
  }

  .mainData p {
    font-size: 0.9rem;
    width: 90%;
    /* margin: auto; */
  }
  .mainData img {
    /* height: 35%; */
    width: 40%;
    /* padding: 0 0 1rem 0; */
    margin: 0 0 3rem 0;
    border-radius: 1rem;
    /* background-color: blue; */
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .serviceimage {
    margin-bottom: rem;
  }
}

@media screen and (max-width: 600px) and (min-width: 500px) {
  .modal-content:nth-child(1) {
    /* height: 110%; */
  }
}
@media screen and (max-width: 700px) and (min-width: 600px) {
  .modal-content:nth-child(1) {
    /* height: 125%; */
  }
  div .modalmain {
    /* height: 100% !important; */
  }
}
@media only screen and (max-width: 680px){
  .services-readmore{
    margin: 1rem auto;
  }
}
@media screen and (max-width: 800px) and (min-width: 700px) {
  .modal-content:nth-child(1) {
    /* height: 100%; */
  }
}

@media only screen and (max-width: 613px) {
  .mainbox {
    grid-template-columns: 80%;
    grid-row-gap: 2%;
    padding: 0.1rem;
    /* background-color: aqua; */
  }
  .Servicemainbody{
    padding-bottom: 2.5rem;
  }
  .mainData p{
    text-align: center;
    width: 100%;
    padding: 0 1rem;
  }
  .modal-content {
    /* height: 115%; */
  }
  .serviceimage {
    /* margin-bottom: 3rem; */
  }
}
@media only screen and (max-width: 562px) {
  .Servicemainbody h2 {
    font-size: 1.3rem;
  }
}
@media only screen and (max-width: 462px) {
  .Servicemainbody h2 {
    font-size: 1.1rem;
  }
  .Servicemainbody p {
    font-size: 0.8rem;
  }
  .mainbox {
    grid-template-columns: 85%;
   }
   .services-readmore{
    margin: 0.5rem auto;
   }
}
@media only screen and (max-width: 365px) {
 .mainbox{
  grid-template-columns: 100%;
 }
} 
@media only screen and (max-width: 350px) {
  .Servicemainbody h2 {
    font-size: 1rem;
    width: 100%;
  }
  .mainbox {
    grid-template-columns: 100%;
    padding: 0.1rem;
    /* background-color: aqua; */
  }
}
@media only screen and (max-width: 300px) {
  .Servicemainbody h2 {
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
  }
}
