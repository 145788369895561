.titleM {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom:.5rem;
  margin:0;
  font-family: "Rubik", sans-serif;
  font-size: 30px;
}

.mainmission {
  background: rgb(205, 231, 239);
  /* width: 80%; */
  margin: auto;
}

.Mgrid {
  display: grid;
  grid-template-columns: 30% 12rem 12rem 30%;
  gap: 1rem 0;
  justify-content: center;
  /* text-align: center; */
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  /* width: 80%; */
  margin: auto;
  text-align: justify;
  background-color: rgb(205, 231, 239);
}
p.missleft {
  margin-right: 20px;
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  font-size: 15px;
}
p.missright {
  margin-left: 20px;
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  font-size: 15px;
}

.box {
  background-color: white;
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
  position: relative;
  width: 7rem;
  height: 6.5rem;
  left: 2.5rem;
  /* bottom: 1rem; */
}
.box:nth-child(odd) {
  left: 1.5rem;
}

.box-heading {
  position: absolute;
  top: 4rem;
  left: 1rem;
  font-size: 1rem;
}
.mission {
  transform: skew(24deg, 0deg);
}

.mission-focus {
  transform: skew(-28deg);
  left:2rem;
}

.mission-expertise {
  transform: skew(-25deg);
}

.mission-vision {
  transform: skew(29deg);
  left:2rem;
}

.box:nth-child(2) {
  /* -ms-transform: rotate(20deg);
  transform: rotate(310deg); */
  transform: skew(-24deg);
}
.box:nth-child(3) {
  transform: skew(28deg);
}
.box:nth-child(6) {
  transform: skew(25deg);
}
.box:nth-child(7) {
  transform: skew(-29deg);
}

.mission-image {
  width: 50%;
  position: absolute;
  top: 1.5rem;
}
.i1,.i2,.i3,.i4{
  position: absolute;
  top:1rem;
  width:2rem;

}
.i1 {
  transform: skew(24deg);
  left: 1.5rem;
}
.i2 {
  transform: skew(-28deg);
  left: 3rem;
}
.i3 {
  transform: skew(-25deg);
  left: 2.5rem;
}
.i4 {
  transform: skew(29deg);
  left: 2rem;
}
.m-icon{
  position:relative;
  width:70%;
}


 @media only screen and (max-width: 1280px){
  .box:nth-child(3),
  .box:nth-child(2) {
    bottom: -1rem;
    top: 0rem;
  }
  .box:nth-child(6),
  .box:nth-child(7) {
    bottom: 0rem;
  }
}  
@media only screen and (max-width: 993px){
  .box:nth-child(3),
  .box:nth-child(2) {
    bottom: -1rem;
    top: 0.5rem;
  }
  .box:nth-child(6),
  .box:nth-child(7) {
    bottom: 2rem;
  }
} 
@media only screen and (max-width: 1000px) {
  .box:nth-child(odd) {
    left: 1.5rem;
  }
}
@media only screen and (max-width: 980px) {
  .Mgrid {
    display: grid;
    grid-template-columns: 30% 8rem 8rem 30%;
  }
  .box {
    height: 6rem;
    width: 6rem;
    left: 0.2rem;
    bottom: -1rem;
  }
  .box:nth-child(3),
  .box:nth-child(2) {
    bottom: -1rem;
    top: 1.5rem;
  }
  .box:nth-child(6),
  .box:nth-child(7) {
    bottom: 1rem;
  }
  .Mgrid{
    gap: 0.5rem 1rem;
  }
}
@media only screen and (max-width: 842px){
  p.missleft,p.missright{
    font-size: 14px;
  }
   .box:nth-child(3),
  .box:nth-child(2) {
    bottom: -1rem;
    top: 1.2rem;
  }
  .box:nth-child(6),
  .box:nth-child(7) {
    bottom: 1.2rem;
  }
}
@media only screen and (max-width: 790px){
  .box:nth-child(3),
  .box:nth-child(2) {
    bottom: -1rem;
    top: 2rem;
  }
  .box:nth-child(6),
  .box:nth-child(7) {
    bottom: 2rem;
  }
}
@media only screen and (max-width: 815px) {
  .box:nth-child(2),
  .box:nth-child(3) {
    bottom: -1rem;
  }
  .Mgrid{
    gap: 0.5rem 0.5rem;
   
  }
  
}
@media only screen and (max-width: 780px) {
  .box:nth-child(2), .box:nth-child(3) {
    bottom: -2.5rem;
}

}
@media only screen and (max-width: 683px) {
  .box:nth-child(2),
  .box:nth-child(3) {
    bottom: -2.5rem;
  }
}

@media only screen and (max-width: 700px) {
  .Mgrid {
    grid-template-columns: 50% 50%;
    /* width: 80%; */
    gap: 1rem 0;
    margin: 1rem;
  }
  p.missleft {
    margin: 0px;
  }
  p.missright {
    margin: 0px;
  }
  .box:nth-child(odd) {
    left: 4rem;
  }
  .box:nth-child(even) {
    left: 5rem;
    top: 0.1rem;
  }
  .i1,.i2,.i3,.i4{
    top:.5rem;
  }
  /* .box:nth-child(n + 1),
  .i1,
  .i2,
  .i3,
  .i4,
  .box h4 {
    transform: skew(0deg);
  } */
  .box:nth-child(3) {
    top: 0;
  }
  .box:nth-child(6) {
    top: 0;
    transform: skew(-24deg);
  }
  .i3,
  .mission-expertise {
    transform: skew(24deg);
  }

  .box:nth-child(7) {
    top: 0;
    transform: skew(28deg);
  }
  .i4,
  .mission-vision {
    transform: skew(-28deg);
  }
  .mission-image {
    top: 0.5rem;
  }
  .i3 {
    left: 1rem;
  }
  .i4 {
    left: 2rem;
  }
  .box-heading {
    top: 3rem;
  }

  .mission,
  .mission-focus,
  .mission-vision {
    left: 1.4rem;
  }

  .Mgrid {
    align-items: center;
  }
  .box {
    display: flex;
  }
}
@media only screen and (max-width: 362px) {
  .box:nth-child(n + 1) {
    left: 2rem;
    top: 1rem;
  }
}