/* .resume-maindiv {
  background: rgba(35, 171, 229, 0.15);
} */
.resume-row {
  /* border: 2px solid red; */
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content:center;
  align-items: center;
  width: 95%;
  /* justify-content: center; */
  margin: 0rem auto;
  text-align: center;
  padding:2rem 0;
}

.select-bg{
    border: 2px solid rgba(0,255,255,.5);
    border-radius: 5px;
}
.resume-modal-body{
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
}
.resume-col1 img {
  /* height: 290px; */
  width: 75%;
  /* object-fit: cover; */
  border-radius: 15px 50px;
  /* text-align: end; */
}

.resume-col2 h2 {
  margin-top: 10px;
  text-align: center;
  color: #219ebc;
}
.resume-col2 p {
  font-size: 15px;
  width: 90%;
  padding-top: 5px;
  padding-bottom: 1rem;
  /* padding-left: 12px;
    padding-right: 11px; */
  text-align: center;
  margin: auto;
}
.btn-submitdiv {
  /* border: 1px solid;  */
  /* align-items: flex-start; */
  text-align: center;
  width: 70%;
  margin: auto;

}
.rbtn {
  background-color: rgba(0, 0, 255, 0.774);
  color: white;
  font-size: 18px;
  margin-right: 9px;
  margin-top: 10px;
  padding:.8rem 1rem;
  border: none;
  /* text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.575); */
  box-shadow: 0px 10px 14px -7px rgba(0, 0, 255, 0.425);
}
.rbtn:hover {
  background-color: rgb(0, 0, 255);
  border: none;
}
.resume-form-col{
  padding: 0.3rem;
}


@media only screen and (max-width: 940px) {
  .resume-row {
    width: 100%;
  }

}
@media only screen and (max-width: 880px) {
  /* .resume-row {
grid-template-columns: 45% 55%;
    width: 100%;
  } */

  .resume-col2 p {
    width: 85%;
    text-align: justify;
    font-size: 1.1rem;
  }
  
  .rbtn {
    margin-right: 5px;
    font-size: 1rem;
  }
}
@media only screen and (max-width: 767px){
  .resume-col1 img {
    height: 230px;
  }
}
@media only screen and (max-width: 745px) {
.resume-row{
    width:100%;
}
}   
@media only screen and (max-width: 694px) {
  
  .resume-col1 img {
    width: 80%;
  }
  .resume-col2 {
    width: 100%;
  }
  

}

@media screen and  (max-width: 991px){
  .resume-modal-body{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .check-Rcontainer-col{
    width: 100%;
  }
  .Rsubmit-btn-col{
    width: 100%;
    text-align: center;
  }
  .resume-form-col{
   
    padding: 1px;
  }
  .gender-padding{
    margin-bottom: 11px;
  }
  .country-padding{
    margin-bottom: 11px;
  }
 .selectjob-width{
  width: 100%;
 }
  
}
@media screen and (max-width: 575px){
  .resume-modal{
    margin-top: 1rem;
  }
}
@media screen and (max-width: 530px){
  .resume-modal-body{
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
  .resume-col2 h2{
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 500px){
  .resume-col2 p{
    width: 100%;
    margin: 0 1rem;
  }
}
@media only screen and (max-width: 690px) {
  .resume-row {
    grid-template-columns: 100%;
  }
  .resume-col2 {
    padding: 0 1rem;
    margin-top: 1rem;
    margin-bottom: 0.7rem;
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .resume-col2 p {
    width: 90%;
    text-align: center;
    padding-top: 0;
  }
  .resume-col2 h2{
    margin-top: 15px;
  }
  .resume-col1 img {
    margin-left: 0rem;
    width: 60%;
    margin: 0rem auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .resume-col1 {
    margin-left: 0rem;
  }
}
@media only screen and (max-width: 471px){
  .resume-col2 h2{
    text-align: center;
  }
}
@media only screen and (max-width: 450px) {
    .resume-col1 img {
        width:80%;
    }
  
}
.label-light {
  color: rgba(0, 0, 0, 0.5) !important; 
  font-size: 15px; 
}
.cross-button {
  background-color: #a1b1dc !important;
  border-radius: 50% !important;
}
@media screen and (max-width: 767px) {
  .resume-form-col.second-upload {
    margin-top: 1rem !important;
  }
}
@media screen and (max-width: 767px) {
  .resume-form-col.msgtxt-width {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
}
@media screen and (max-width: 767px) {
  .resume-form-col.second-upload {
    margin-top: 1rem !important;
  }
  .resume-form-col.msgtxt-width {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .resume-form-col.upload-width {
    margin-bottom: 1rem !important;
  }
}
@media screen and (max-width: 767px) {
  .check {
    font-size: 0.8rem; /* adjust the font size to your preference */
  }
  p.check {
    font-size: 0.8rem; /* adjust the font size to your preference */
  }
  html {
    font-size: 16px;
  }
}

/* Detail.css */
.upload-section {
  margin-bottom: 0; /* Default margin for larger screens */
}

/* Mobile view */
@media (max-width: 768px) {
  .upload-section {
    margin-bottom: 1rem; /* Margin for mobile view */
  }
}
.dark-text {
  color: #000 !important;
  font-weight: 600;
}