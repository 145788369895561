.gridcontainer{
    display: grid;
    /* grid-template-rows:30%; */
   
    grid-template-columns: 50% 50%;
    background-color:#1f68ae;
    /* padding-top: 2%; */
    justify-content: space-around;
    width: 100%;
}
.header-photo {
    width: 100%; /* Set the width as needed */
    height: 300px; /* Set a fixed height for the container */
    object-fit: cover; /* Ensures the image covers the container */
    object-position: bottom; /* Aligns the image to the top, making the upper part more visible */
}

.contentheader{
    width: 90%;
    margin: 80px auto;
    color: white;
}
.headerimage img{
    height:100%;
    width: 50%;
    margin-left: 30%;
    
    
}

.contentheader p{
    font-size: 1rem;
}

/* @media only screen and (max-width: 500px) {
    .gridcontainer{
        grid-template-columns: 100%;
    }
    .contentheader{
        width: 90%;
        margin: 40px auto;
        color: white;
    }
    .contentheader p{
        font-size: .7rem;
    }
  } */
  @media only screen and (max-width: 1100px) {
    .contentheader{
        width: 87%;
        margin: 40px auto;
        color: white;
    }
    .headerimage img{
        width: 65%;
        margin-left: 15%;
    }
}
  @media only screen and (max-width: 900px) {
    .contentheader{
        width: 87%;
        margin: 30px auto;
        color: white;
    }
    .headerimage img{
        /* height:100%; */
        width: 70%;
        /* display: block;
        margin-left: auto;
        margin-right: auto; */
        margin-left: 15%;
        
        
    }
    .contentheader p{
        font-size: .8rem;
    }
  }
  @media only screen and (max-width: 665px) {
    .contentheader p{
        font-size: .75rem;
    }
    .headerimage img{
        width: 70%;
        margin-left: 20%;
    }
  }
  @media only screen and (max-width: 630px) {
    .contentheader p{
        font-size: .8rem;
    }
    .headerimage img{
        width: 70%;
        margin-left: 20%;
    }
    .contentheader h1{
        font-size: 1.4rem;
    }
  }
  @media only screen and (max-width: 500px) {
    .headerimage img{
        width: 80%;
        margin-left: 5%;
    }
    .contentheader p{
        font-size: .7rem;
    }
    .contentheader h1{
        font-size: 1.3rem;
    }
  }

  @media only screen and (max-width: 400px) {
    .gridcontainer{
        grid-template-columns: 100%;
    }
    /* .contentheader{
        width: 90%;
        margin: 40px auto;
        color: white;
    } */
    .contentheader p{
        font-size: .7rem;
    }
    .headerimage img{
        /* height:100%; */
        width: 60%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .about-text{
        top: 10%;
    }
  }
